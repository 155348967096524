import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`5. NO SELLING OF INFORMATION AND LIMITED SHARING`}</h2>
    <ul>
      <li parentName="ul">{`We do NOT collect financial or payment source information. `}</li>
      <li parentName="ul">{`We do NOT share identity information unless legally compelled to.`}</li>
      <li parentName="ul">{`HostJane expressly does NOT sell any of the limited information we hold about You to any third party.`}</li>
    </ul>
    <h3>{`5.1 POLICE OR PAYMENT PROVIDER INVESTIGATIONS`}</h3>
    <p>{`Identity documents are held on encrypyed servers and used only to verify or authenticate information provided by You, or in response to Authorities, Amazon Pay or PayPal requests for information.`}</p>
    <h3>{`5.2 SHARING WITH THIRD PARTY SERVICE PROVIDERS AND VENDORS`}</h3>
    <p>{`We don't collect any payment information from You. Any Personal Information is held strictly private and confidential, on encrypted and secure AWS servers and handled only as provided below:`}</p>
    <h3>{`5.2.1 SELLERS DO NOT RECEIVE PERSONAL INFORMATON`}</h3>
    <p>{`If You purchase a product or service from a third party through HostJane, We will keep Your personal information including Your name and email private, and pass on as limited amount as is practical of Your information to such third parties to fulfill Your order. For example, We may share non-personal information with Your Seller, such as Your Job title and Jib Requirements, necessary for the Seller to fulfill Your Order.`}</p>
    <h3>{`5.2.2 ANONYMOUS LIVING COST DONATIONS`}</h3>
    <p>{`If You check the anonymous button to anonymously donate funds towards a User's living costs as described `}<a parentName="p" {...{
        "href": "/legal/marketplace-terms/"
      }}>{`in the Marketplace Rules`}</a>{` as a `}<em parentName="p">{`Random Act of Kindness`}</em>{` defined in `}<strong parentName="p">{`1.29`}</strong>{` as a charitable good deed, we will not disclose any information regarding Your donation to the donation recipient or any third-party except in response to a Police or Payment Provider Information.`}</p>
    <h3>{`5.2.3 CONTRACTS TO PROVIDE OUR SERVICE`}</h3>
    <p>{`HostJane may enter into contracts with carefully selected third parties to assist Us in servicing
You (for example, providing You with customer service, fraud detection and deterrence or access
to advertising assets and providing Us with information technology and storage services) or to
assist Us in Our own marketing and advertising activities (including providing Us with analytic
information and search engine optimization services). Our contracts with such third parties prohibit
them from using any of Your personal information for any purpose beyond the purpose for which it
was shared.`}</p>
    <h3>{`5.3 CORPORATE REORGANIZATIONS`}</h3>
    <p>{`If We are involved in a merger, acquisition, a sale of all or a substantial portion of Our assets,
or other similar sale transaction, Your information will be transferred as part of that transaction.
We will notify You by email and/or a prominent notice on Our website of any such transfer and any
choices You may have regarding Your information.`}</p>
    <h3>{`5.4 AUTHORIZED USERS`}</h3>
    <p>{`All users authorized by You to have access to Your account can view personal information stored in
the account. A primary account holder can view personal information saved in subaccounts to which
they have authorized access. We share information about authorized users only for legitimate purposes
consistent with this privacy policy, including servicing Your account and marketing products and
Services to You.`}</p>
    <h3>{`5.5 LEGAL PROCESS`}</h3>
    <p>{`If legally required to do so, or if We have a good faith belief that such disclosure is reasonably
necessary, We may disclose Your personal information to courts of law, public authorities
(including to meet national security or law enforcement requirements), and other relevant third
parties, such as internet service providers, to conduct an investigation, respond to a third party
or law enforcement subpoena or court order, to bring legal action, prevent harm to others or pursue
other relief when You or a third party are or may be:`}</p>
    <ul>
      <li parentName="ul">{`Violating Our terms and conditions of use;`}</li>
      <li parentName="ul">{`Causing injury or other harm to, or otherwise violating the property or other legal rights, of Us, other users, or third parties; or`}</li>
      <li parentName="ul">{`Violating federal, state, local, or other applicable law.`}</li>
    </ul>
    <p>{`This disclosure can include transferring Your information to the U.S. and other countries outside
the European Economic Area. To the extent We are legally permitted to do so, We will notify You
in the event that We are required to provide Your personal information to third parties in connection
with a subpoena.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      